import React from "react"
import "../styles/main.scss"
import Layout from "../components/layout"
import { Link, graphql, useStaticQuery } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faAngleDown } from "@fortawesome/free-solid-svg-icons"

// import indexStyles from "./index.module.scss"
import Img from "gatsby-image"

// import scrollTo from "gatsby-plugin-smoothscroll"
// import Hero from "../components/hero"

import BackgroundImage from "gatsby-background-image"

import fsk from "../images/kaphato_FSK-logo.png"
import libri from "../images/kaphato_libri-logo.png"
import lira from "../images/kaphato_lira-logo.png"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      books: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(index/konyvek)/" } }
        sort: { order: DESC, fields: [frontmatter___id] }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              id

              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            html
            excerpt
            fields {
              slug
            }
          }
        }
      }

      qigong: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(index/qigong)/" } }
        sort: { order: ASC, fields: [frontmatter___id] }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              id
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            html
            excerpt(pruneLength: 130)
            fields {
              slug
            }
          }
        }
      }

      mastheadImage: file(relativePath: { eq: "images/romok_1920_1080.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  // console.log(data)

  return (
    <Layout>
      {/* <!-- Masthead--> */}

      <BackgroundImage
        fluid={data.mastheadImage.childImageSharp.fluid}
        className=""
      >
        <header className=" masthead d-flex">
          <div className="container text-center my-auto pb-6">
            <h1 className="mb-1">Süli István</h1>
            <h3 className="mb-5">
              <em>Író, tanár, művelődéstörténész...</em>
            </h3>
            <Link
              to="#konyvek-index"
              className="btn btn-primary btn-sm js-scroll-trigger px-5 py-0"
              // onClick={() => scrollTo("#konyvek")}
              // href="#konyvek"
            >
              <FontAwesomeIcon icon={faAngleDown} />
            </Link>
          </div>
        </header>
      </BackgroundImage>

      <main
        id="index_page"
        data-spy="scroll"
        data-target="#navbar"
        data-offset="50"
      >
        {/* <--! Section I - Könyvek -> */}
        <section id="konyvek-index" className="section">
          <div className="container">
            <h1 className="page-header text-center pt-6">Könyvek</h1>
            <hr></hr>
            <div className="row">
              <div className="col">
                <div className="card-deck mx-auto ">
                  {data.books.edges.map(edge => {
                    return (
                      <div
                        className="col-lg-4 col-md-6 col-sm-12 col-12 pb-4 mx-auto"
                        key={edge.node.id}
                      >
                        <div className="card h-100">
                          <div className="card-hover">
                            <Img
                              className="card-img-top"
                              fluid={
                                edge.node.frontmatter.featuredImage
                                  .childImageSharp.fluid
                              }
                              alt=""
                            />
                            <Link
                              to={edge.node.fields.slug}
                              className="btn btn-primary reveal py-3 px-5 w-100 "
                            >
                              Ismertető
                            </Link>
                          </div>
                          <div className="card-body">
                            <h5 className="card-title text-center">
                              {edge.node.frontmatter.title}
                            </h5>
                            <h6
                              className="card-subtitle text-center"
                              dangerouslySetInnerHTML={{
                                __html: edge.node.html,
                              }}
                            ></h6>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <--! Section I/a - Kaphato -> */}
        <section>
          <div className="container" id="">
            <div className="row mx-0 pb-5">
              <div className="col-md-12" id="kaphato">
                <h3 className="text-center pt-4">A könyvek megvásárolhatóak</h3>

                <hr />
                <div className="d-flex align-items-center">
                  <div className="col-md-4">
                    <a
                      href="https://feketesas.hu/authors/suli-istvan/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={fsk}
                        alt="Fekete Sas Kiadó"
                        className="img-fluid"
                      ></img>
                    </a>
                  </div>

                  <div className="col-md-4">
                    <a
                      href="https://www.libri.hu/talalati_lista/?docpath=irodalom&text=s%C3%BCli+istv%C3%A1n"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={libri}
                        alt="Libri Könyvkereskedelmi Kft."
                        className="img-fluid"
                      ></img>
                    </a>
                  </div>

                  <div className="col-md-4">
                    <a
                      href="https://www.lira.hu/hu/reszletes_kereso?listorder=release_date&listdirection=desc&listpagenumber=20&listcurrentpage=0&quick_search_txt=s%C3%BCli%20istv%C3%A1n&quick_search_category_code="
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={lira}
                        alt="Líra Könyv Zrt."
                        className="img-fluid"
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <--! Section II - Írások -> */}

        {/*
        <section id="irasok-index" className="section">
          <div className="container">
            <h1 className="page-header text-center pt-6">Egyéb írások</h1>
            <hr></hr>
            <div className="row">
              <div className="col-md-12">
                <div className="card-group">
                  {data.writings.edges.map(edge => {
                    return (
                      <div className="col-md-3" key={edge.node.id}>
                        <div className="card h-100">
                          <div className="card-hover">
                            <div className="card-body">
                              <Link to={edge.node.fields.slug}>
                                <Img
                                  className="card-img-top mx-auto"
                                  fluid={
                                    edge.node.frontmatter.icon.childImageSharp
                                      .fluid
                                  }
                                  alt=""
                                />
                                <h5 className="card-title text-center reveal pt-4">
                                  {edge.node.frontmatter.title}
                                </h5>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
                */}
        {/* <--! Section III - Chi Kung -> */}
        <section id="chi_kung-index" className="section">
          <div className="container">
            <h1 className="page-header text-center pt-6">Chi Kung (Qi Gong)</h1>
            <hr></hr>
            <div className="row">
              <div className="col-md-12">
                <div className="card-group">
                  {data.qigong.edges.map(edge => {
                    return (
                      <div
                        className="col-lg-6 col-md-12  col-sm-12 col-12 pl-0 pr-0"
                        key={edge.node.id}
                      >
                        <div className="card">
                          <Img
                            className="card-img-top"
                            fluid={
                              edge.node.frontmatter.featuredImage
                                .childImageSharp.fluid
                            }
                            alt=""
                          />
                          <div className="card-img-overlay">
                            <div className="card-body">
                              <h5 className="card-title text-center">
                                {edge.node.frontmatter.title}
                              </h5>
                              <h6
                                className="card-text d-none d-sm-block"
                                dangerouslySetInnerHTML={{
                                  __html: edge.node.excerpt,
                                }}
                              ></h6>
                            </div>
                            <div className="text-center pt-md-5 pt-sm-3 pt-2">
                              <Link
                                to={`/qigong/${edge.node.fields.slug}`}
                                className="btn btn-secondary py-3 px-5 mx-auto"
                              >
                                Tovább
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>

            <hr></hr>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default IndexPage
